<script lang="ts">
  import dayjs from 'dayjs'
  import { createEventDispatcher } from 'svelte'
  import { fade } from 'svelte/transition'
  import { axiosInstance } from '@/plugins/axios'
  import { i18n, showErrorMessage } from '@/plugins/util'
  import { isLoading, lang } from '@/store'

  const dispatch = createEventDispatcher()

  export let view: 'question' | 'form' | 'success' = 'question'
  export let showCloseButton: true

  let dobYear = ''
  let dobMonth = ''
  let dobDay = ''
  let gender: 'M' | 'F' | 'N' | '' = ''
  let errorMessage: string | null = null

  const days = [
    '01', '02', '03', '04', '05', '06', '07', '08', '09', '10',
    '11', '12', '13', '14', '15', '16', '17', '18', '19', '20',
    '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'
  ]
  const years = []

  $: dob = `${dobYear}-${dobMonth}-${dobDay}`;

  // ================================================================================

  const maxYear = new Date().getFullYear() - 13
  for (let year = maxYear; year >= 1931; year--) {
    years.push(year)
  }

  // ================================================================================

  async function register() {
    if (dobDay === '') {
      errorMessage = i18n($lang, ['Please select day', 'ກະລຸນາເລືອກວັນ'])
      return
    }
    else if (dobMonth === '') {
      errorMessage = i18n($lang, ['Please select month', 'ກະລຸນາເລືອກເດືອນ'])
      return
    }
    else if (dobYear === '') {
      errorMessage = i18n($lang, ['Please select year', 'ກະລຸນາເລືອກປີ'])
      return
    }
    else if (gender === '') {
      errorMessage = i18n($lang, ['Please select gender', 'ກະລຸນາເລືອກເພດ'])
      return
    }
    else if (dayjs(dob, 'YYYY-MM-DD').format('YYYY-MM-DD') !== dob) {
      errorMessage = i18n($lang, ['Please select valid birthday', 'ວັນເກີດບໍ່ຖືກຕ້ອງ'])
      return
    }

    try {
      errorMessage = null
      $isLoading = true

      await axiosInstance.post('/webapp/customer/register-reward', {
        dob: dob,
        gender: gender,
      })

      $isLoading = false
      view = 'success'

    } catch (error) {
      $isLoading = false

      if (error.response) {
        showErrorMessage(error.response.data.message)
      } else if (error.request) {
        showErrorMessage(i18n($lang, ['Cannot connect to server, please try again', 'ບໍ່ສາມາດເຊື່ອມຕໍ່ລະບົບ ກະລຸນາລອງໃໝ່']))
      } else {
        console.error(`BUY_VEHICLE_INSURANCE_ERROR: ${error.message}`)
        showErrorMessage(i18n($lang, ['Please try again', 'ກະລຸນາລອງໃໝ່']))
      }
    }
  }
</script>

<div class="modal" transition:fade="{{ duration: 100 }}">

  {#if view === 'question'}
  <div class="flex flex-col rounded bg-white shadow-lg">
    <h1 class="text-blue-primary text-2xl text-center p-8">
      {i18n($lang, ['Do you want to register', 'ທ່ານຕ້ອງການລົງທະບຽນ'])}<br>{i18n($lang, ['Loca Benefits', 'ເພື່ອຮັບຂອງລາງວັນໂລກ້າບໍ່?'])}?
    </h1>
    <div class="flex p-2 pt-0">
      <div class="flex-1 text-center text-xl text-white m-2 p-2 rounded bg-gray-300" on:click={() => dispatch('close')}>
        {i18n($lang, ['No', 'ບໍ່ຕ້ອງການ'])}
      </div>
      <div class="flex-1 text-center text-xl text-white m-2 p-2 rounded bg-blue-primary" on:click={() => view = 'form'}>
        {i18n($lang, ['Yes', 'ຕ້ອງການ'])}
      </div>
    </div>
  </div>
  {/if}

  {#if view === 'form'}
  <div class="flex flex-col rounded bg-white shadow-lg">
    <header class="text-center px-2 pt-8 pb-2">
      <h1 class="text-3xl font-medium">{i18n($lang, ['Basic Information', 'ຂໍ້ມູນພື້ນຖານ'])}</h1>
      <p class="text-lg">{i18n($lang, ['Enter your birthday and gender', 'ກະລຸນາເລືອກວັນທີ/ເດືອນ/ປີເກີດ ແລະ ເພດ'])}</p>
    </header>

    {#if errorMessage}
    <div
      class="text-center text-red-600 font-medium border border-red-700 bg-red-50 p-2 mx-4 mt-2 rounded"
      transition:fade="{{ duration: 100 }}">
      {errorMessage}
    </div>
    {/if}

    <form class="p-2">
      <div class="flex">
        <select required class="form-control m-2 w-[100px]" bind:value={dobDay}>

          <option value="" disabled selected>{i18n($lang, ['Day', 'ວັນທີ'])}</option>
          {#each days as day}
          <option value="{day}">{day}</option>
          {/each}
        </select>

        <select required class="form-control my-2 mx-0 w-[100px]" bind:value={dobMonth}>
          <option value="" disabled selected>{i18n($lang, ['Month', 'ເດືອນ'])}</option>
          <option value="01">{i18n($lang, ['January', 'ມັງກອນ'])}</option>
          <option value="02">{i18n($lang, ['February', 'ກຸມພາ'])}</option>
          <option value="03">{i18n($lang, ['March', 'ມີນາ'])}</option>
          <option value="04">{i18n($lang, ['April', 'ເມສາ'])}</option>
          <option value="05">{i18n($lang, ['May', 'ພຶດສະພາ'])}</option>
          <option value="06">{i18n($lang, ['June', 'ມິຖຸນາ'])}</option>
          <option value="07">{i18n($lang, ['July', 'ກໍລະກົດ'])}</option>
          <option value="08">{i18n($lang, ['August', 'ສິງຫາ'])}</option>
          <option value="09">{i18n($lang, ['September', 'ກັນຍາ'])}</option>
          <option value="10">{i18n($lang, ['October', 'ຕຸລາ'])}</option>
          <option value="11">{i18n($lang, ['November', 'ພະຈິກ'])}</option>
          <option value="12">{i18n($lang, ['December', 'ທັນວາ'])}</option>
        </select>

        <select required class="form-control m-2 w-[100px]" bind:value={dobYear}>
          <option value="" disabled selected>{i18n($lang, ['Year', 'ປີ'])}</option>
          {#each years as year}
          <option value="{year}">{year}</option>
          {/each}
        </select>

      </div>

      <div class="p-2 pt-0">
        <select required class="form-control w-full" bind:value={gender}>
          <option value="" disabled selected>{i18n($lang, ['Gender', 'ເພດ'])}</option>
          <option value="M">{i18n($lang, ['Male', 'ຊາຍ'])}</option>
          <option value="F">{i18n($lang, ['Female', 'ຍິງ'])}</option>
          <option value="N">{i18n($lang, ['Not Specific', 'ບໍ່ລະບຸ'])}</option>
        </select>
      </div>

      <div class="flex-1 flex justify-center items-center text-xl text-white m-2 mb-8 rounded bg-blue-primary h-[48px]" on:click={register}>
        {i18n($lang, ['Register', 'ລົງທະບຽນ'])}
      </div>

      {#if showCloseButton}
        <div class="text-center my-8">
          <span class="underline text-gray-400" on:click={() => dispatch('close')}>
            {i18n($lang, ['Close', 'ປິດ'])}
          </span>
        </div>
      {/if}

    </form>
  </div>
  {/if}

  {#if view === 'success'}
    <div class="flex flex-col rounded bg-white shadow-lg mx-2">
      <h1 class="text-blue-primary text-2xl text-center p-8">
        {i18n($lang, ['Thank you for signup Loca Member. Here is your 20 points complimentary', 'ຂໍຂອບໃຈທີ່ທ່ານສະໝັກ Loca Member. ນີ້ແມ່ນຄະແນນຟຮີຈຳນວນ 20 ຄະແນນ'])}
      </h1>
      <div class="flex p-2 pt-0">
        <div class="flex-1 text-center text-xl text-white m-2 p-2 rounded bg-blue-primary" on:click={() => dispatch('registerSuccess')}>
          {i18n($lang, ['Okay', 'ຕົກລົງ'])}
        </div>
      </div>
    </div>
  {/if}

</div>

<style lang="scss">
  .modal {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);
  }

  .form-control {
    @apply border;
    @apply border-gray-300;
    @apply rounded;
    @apply text-gray-600;
    height: 48px;
    text-align-last: center;
    -moz-text-align-last: center;
    font-size: 18px;
    text-align: center;
    outline: none;

    &::placeholder {
      color: inherit;
    }
  }
</style>
