import Swal from 'sweetalert2'
import ClipboardJS from 'clipboard'
import { lang, userProfile } from '@/store'
import { get } from 'svelte/store'
import axios from 'axios'

export const LOCA_IMAGE_UPLOAD_BASE_URL = `https://locagroshop.sgp1.digitaloceanspaces.com`

export function i18n(lang: string, stringArr: string[]): string {
  if (stringArr.length === 1) return stringArr[0]
  const index = lang === 'lo' ? 1 : 0
  return stringArr[index]
}

/* https://www.geodatasource.com/developers/javascript */
export function getDistance(lat1: number, lon1: number, lat2: number, lon2: number, unit: 'M' | 'K' | 'N' = 'K'): number {
  if ((lat1 === lat2) && (lon1 === lon2)) {
    return 0
  }

  const radlat1 = Math.PI * lat1/180
  const radlat2 = Math.PI * lat2/180
  const theta = lon1-lon2
  const radtheta = Math.PI * theta/180
  let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
  if (dist > 1) {
    dist = 1
  }
  dist = Math.acos(dist)
  dist = dist * 180 / Math.PI
  dist = dist * 60 * 1.1515
  if (unit === 'K') dist = dist * 1.60934
  if (unit === 'M') dist = dist * 0.8684
  return dist
}

export function getImageUrl(key, { width }) {
  return `https://locacore.gumlet.io/${key}?compress=true,format=auto,q=100,w=${width}`
}

export function getImageCDNUrl(params: {url: string, width: number, height?: number}) {
  let _url = `https://images.weserv.nl/?url=${params.url}&w=${params.width}&q=90&output=webp`
  if (params.height) {
    _url += `&h=${params.height}`
  }
  return _url
}

export function showErrorMessage(message: string) {
  Swal.fire({
    icon: 'error',
    text: message,
    showConfirmButton: false,
    timer: 8000,
    timerProgressBar: true,
    confirmButtonColor: '#1CAAFC',
  })
}

export function formatGender(lang, gender) {
  switch (gender) {
    case 'male': return lang === 'lo' ? 'ຊາຍ' : 'Male'
    case 'female': return lang === 'lo' ? 'ຍິງ' : 'Female'
    default: return gender
  }
}

export function formatInsuranceDocumentType(lang, type) {
  switch (type) {
    case 'id_card': return lang === 'lo' ? 'ບັດປະຈຳຕົວ' : 'ID Card'
    case 'passport': return lang === 'lo' ? 'ພາສປອດ' : 'Passport'
    default: return type
  }
}

export function translateCCY(lang, ccy) {
  switch (ccy) {
    case 'LAK': return lang === 'lo' ? 'ກີບ' : 'Kip'
    case 'THB': return lang === 'lo' ? 'ບາດ' : 'Baht'
    case 'USD': return lang === 'lo' ? 'ໂດລາ' : 'Dollars'
    default: return ccy
  }
}

export function getAirPickerLocale(lang) {
  return {
    days: [
      i18n(lang, ['Sunday', 'ອາທິດ']),
      i18n(lang, ['Monday', 'ຈັນ']),
      i18n(lang, ['Tuesday', 'ຄານ']),
      i18n(lang, ['Wednesday', 'ພຸດ']),
      i18n(lang, ['Thursday', 'ພະຫັດ']),
      i18n(lang, ['Friday', 'ສຸກ']),
      i18n(lang, ['Saturday', 'ເສົາ']),
    ],
    daysShort: [
      i18n(lang, ['Sun', 'ທິດ']),
      i18n(lang, ['Mon', 'ຈັນ']),
      i18n(lang, ['Tue', 'ຄານ']),
      i18n(lang, ['Wed', 'ພຸດ']),
      i18n(lang, ['Thu', 'ພະຫັດ']),
      i18n(lang, ['Fri', 'ສຸກ']),
      i18n(lang, ['Sat', 'ເສົາ']),
    ],
    daysMin: [
      i18n(lang, ['Su', 'ທິດ']),
      i18n(lang, ['Mo', 'ຈັນ']),
      i18n(lang, ['Tu', 'ຄານ']),
      i18n(lang, ['We', 'ພຸດ']),
      i18n(lang, ['Th', 'ພະຫັດ']),
      i18n(lang, ['Fr', 'ສຸກ']),
      i18n(lang, ['Sa', 'ເສົາ']),
    ],
    months: [
      i18n(lang, ['January', 'ມັງກອນ']),
      i18n(lang, ['February', 'ກຸມພາ']),
      i18n(lang, ['March', 'ມີນາ']),
      i18n(lang, ['April', 'ເມສາ']),
      i18n(lang, ['May', 'ພຶດສະພາ']),
      i18n(lang, ['June', 'ມິຖຸນາ']),
      i18n(lang, ['July', 'ກໍລະກົດ']),
      i18n(lang, ['August', 'ສິງຫາ']),
      i18n(lang, ['September', 'ກັນຍາ']),
      i18n(lang, ['October', 'ຕຸລາ']),
      i18n(lang, ['November', 'ພະຈິກ']),
      i18n(lang, ['December', 'ທັນວາ']),
    ],
    monthsShort: [
      i18n(lang, ['Jan', 'ມັງກອນ']),
      i18n(lang, ['Feb', 'ກຸມພາ']),
      i18n(lang, ['Mar', 'ມີນາ']),
      i18n(lang, ['Apr', 'ເມສາ']),
      i18n(lang, ['May', 'ພຶດສະພາ']),
      i18n(lang, ['Jun', 'ມິຖຸນາ']),
      i18n(lang, ['Jul', 'ກໍລະກົດ']),
      i18n(lang, ['Aug', 'ສິງຫາ']),
      i18n(lang, ['Sep', 'ກັນຍາ']),
      i18n(lang, ['Oct', 'ຕຸລາ']),
      i18n(lang, ['Nov', 'ພະຈິກ']),
      i18n(lang, ['Dec', 'ທັນວາ']),
    ],
    today: i18n(lang, ['Today', 'ມື້ນີ້']),
    clear: i18n(lang, ['Clear', 'ລ້າງ']),
  }
}

export function getAssetsImageUrl(filename) {
  return new URL(`/src/assets/${filename}`, import.meta.url).href
}

export function initClipboard() {
  const clipboard = new ClipboardJS('.js-clipboard')

  clipboard.on('success', (e) => {
    const Toast = Swal.mixin({
      toast: true,
      position: 'center',
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: false,
      width: '240px',
    })
    Toast.fire({
      icon: 'success',
      title: i18n(get(lang), ['Copied', 'ກ໋ອບປີ້ສຳເລັດ']),
    })
  })

  clipboard.on('error', (e) => {
    console.error('Clipboard Error', e)
  })
}

export function openGoogleMap(latitude: number, longitude: number) {
  window.open(`https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`, '_blank')
}

export function sendDebugLog(data: object) {
  axios.post('https://locacoreprod.laodev.net/logs', {
    data,
    userProfile: get(userProfile),
  })
}
