import { writable, readable } from 'svelte/store'
import type { Writable, Readable } from 'svelte/store'
import type { AppUserProfile, AppUserPosition } from './types/App'

export const userProfile: Writable<AppUserProfile | null> = writable(null)

export const userPosition: Writable<AppUserPosition | null> = writable(null)

export const hasLocaToken: Readable<boolean> = readable((new URLSearchParams(window.location.search)).get('token') !== null)

/** Lao = lo, English = en */
export const lang: Writable<string> = writable(undefined, function start(set) {
  const urlParams = new URLSearchParams(window.location.search)
  const useLang = urlParams.has('user_lang') ? urlParams.get('user_lang').toLocaleLowerCase() : 'en'
  set(useLang)
	return function stop() {}
})

export const isLoading: Writable<boolean> = writable(false)
