import 'sweetalert2/src/sweetalert2.scss'
import 'virtual:windi.css'
import 'virtual:windi-devtools'
import 'air-datepicker/air-datepicker.css'

import './app.scss'
import App from './App.svelte'

import * as Sentry from '@sentry/svelte'
import { BrowserTracing } from '@sentry/tracing'

Sentry.init({
  dsn: 'https://f1361e20d23f42458024a67df9d9a75f@o1167004.ingest.sentry.io/6257659',
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: process.env.NODE_ENV,
})

const app = new App({
  target: document.getElementById('app')
})

export default app
