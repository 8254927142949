<script lang="ts">
  import { onMount } from 'svelte'
  import LoadingIndicator from '@/components/LoadingIndicator.svelte'
  import ModalRegisterQuestion from '@/components/ModalRegisterQuestion.svelte'
  import { axiosInstance } from '@/plugins/axios'
  import { i18n, initClipboard } from '@/plugins/util'
  import { hasLocaToken, isLoading, lang, userProfile } from '@/store'
  import PubSub from 'pubsub-js'
  import Swal from 'sweetalert2'
  import { useRegisterSW } from 'virtual:pwa-register/svelte'

  // PWA
  const { needRefresh } = useRegisterSW({
    onRegistered(swr) {
      console.log(`SW registered: ${swr}`)
    },
    onRegisterError(error) {
      console.log('SW registration error', error)
    }
  })

  if ($needRefresh === true) {
    window.location.reload()
  }

  let appComponent
  let showRegisterModal = false

  const urlParams = new URLSearchParams(window.location.search)
  const appName = urlParams.has('app') ? urlParams.get('app') : 'reward'

  // ================================================================================

  onMount(() => {
    if ($hasLocaToken) {
      localStorage.removeItem('LOCA_ACCESS_TOKEN')
    }

    if (['reward', 'insurance'].includes(appName)) {
      checkCustomer()
    }

    loadAppComponent()
    initClipboard()
  })

  // ================================================================================

  async function checkCustomer() {
    try {
      $isLoading = true
      const { data } = await axiosInstance.get(`/webapp/customer/check`)

      $userProfile = {
        uuid: data['uuid'],
        name: data['name'],
        email: data['email'],
        tel: data['tel'],
        profileImage: data['profile_image'] ?? './images/no_profile_image.png',
        registeredRewardAt: data['registered_reward_at'],
        gender: data['gender'],
        dob: data['dob'],
        points: data['points'],
        heinekenRegisteredAt: null,
      }
      $isLoading = false

      if (data['registered_reward_at'] === null) {
        showRegisterModal = true
      }

    } catch (error) {
      $isLoading = false
      let errorMessage = ''

      if (error.response) {
        errorMessage = error.response.data.message
      } else if (error.request) {
        errorMessage = i18n($lang, ['Cannot connect to server, please try again', 'ບໍ່ສາມາດເຊື່ອມຕໍ່ລະບົບ ກະລຸນາລອງໃໝ່'])
      } else {
        console.error(`BUY_VEHICLE_INSURANCE_ERROR: ${error.message}`)
        errorMessage = i18n($lang, ['Please try again', 'ກະລຸນາລອງໃໝ່'])
      }

      Swal.fire({
        icon: 'error',
        text: errorMessage,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        confirmButtonColor: '#1CAAFC',
      })
    }
  }

  function loadAppComponent() {
    switch (appName) {
      case 'reward':
        import('@/components/appReward/AppReward.svelte').then(res => appComponent = res.default)
        break
      case 'insurance':
        import('@/components/appInsurance/AppInsurance.svelte').then(res => appComponent = res.default)
        break
      case 'heineken':
        import('@/components/appHeineken/AppHeineken.svelte').then(res => appComponent = res.default)
        break
      case 'driver':
        import('@/components/appDriver/App.svelte').then(res => appComponent = res.default)
        break
    }
  }

  function handleRewardRegisterSuccess() {
    showRegisterModal = false
    if (appName === 'reward') {
      // REFRESH USER POINT AND HISTORIES
      PubSub.publish('REFRESH_REWARD_USER')
      PubSub.publish('REFRESH_REWARD_PAYLOAD')
    }
    else if (appName === 'insurance') {
      // REFRESH CUSTOMER INFO
      checkCustomer()
    }
  }
</script>

<LoadingIndicator/>

{#if showRegisterModal}
  <ModalRegisterQuestion
    view={appName === 'insurance' ? 'form' : 'question'}
    showCloseButton={appName !== 'insurance'}
    on:close={() => showRegisterModal = false}
    on:registerSuccess={handleRewardRegisterSuccess}/>
{/if}

<svelte:component this={appComponent} />
