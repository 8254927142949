<script lang="ts">
  import { isLoading } from '@/store'
</script>

{#if $isLoading}
<aside class="loading-wrapper">
  <div class="loading-container">
    <div class="loading-indicator">
      <svg version="1.1" id="Loader" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="-2.5 -2.5 49 49">
        <circle class="path_circle" fill="none" stroke="#fff" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" cx="22" cy="22" r="22"></circle>
      </svg>
    </div>
  </div>
</aside>
{/if}

<style lang="scss">
.loading-wrapper {
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: loadingBackgroundToBlack 0.5s linear;
}

.loading-container {
  visibility: visible;
  background-color: #F7FAFC;
  padding: 16px;
  border-radius: 100%;
  box-shadow: 0 10px 20px hsla(0, 0%, 0%, .15);
  display: flex;
  justify-content: center;
  align-items: baseline;
}

@keyframes loadingBackgroundToBlack {
  from {
    background-color: transparent;
  }
}

// https://codepen.io/beefchimi/pen/yHmeq
@keyframes rotator {
  to {
    transform: rotate(360deg);
  }
}

@keyframes loadpath {
  to {
    stroke-dashoffset: -138;
  }
}

div.loading-indicator {
  $loading-indicator-size: 36px;
  animation: rotator 1.6s linear infinite forwards;
  margin: auto;
  width: $loading-indicator-size;
  height: $loading-indicator-size;

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }

  circle {
    stroke-width: 4px;
    stroke: #1CAAFC;
    stroke-dasharray: 138;
    stroke-dashoffset: 138;
    animation: loadpath 1.6s linear infinite forwards;
  }
}
</style>
